.flex-features {
  padding-top: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.Right-features-sec {
  padding-top: 2rem;
}

.Right-features-sec h6 {
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  line-height: 54px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.Right-features-sec p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: rgba(107, 107, 107, 1);
}

.card-true {
  display: flex;
  flex-direction: row;
  column-gap: 2%;
  margin-top: 0.3rem;
}

.card-true img {
  width: 26px;
  height: 26px;
}

.card-true h6 {

  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.text-key-features {

  margin-top: 3.5rem;

}


.text-key-features h6 span {
  text-align: left;
  font-family: "Link Sans ExtraBold";
  letter-spacing: -0.18px;
  color: #202020;
  text-transform: capitalize;
  font-size: 44px;
  font-weight: bold;

}

.text-key-features h6 {
  font-family: "Rowdies", sans-serif;
  letter-spacing: -0.18px;
  color: #4925E1;
  text-transform: capitalize;
  font-size: 44px;
  font-weight: bold;

}

.text-key-features p {


  font: normal normal 300 15px/20px Link Sans;
  color: #6B6B6B;
  text-transform: capitalize;
  font-family: "Rowdies", sans-serif;
  font-size: 15px;

}

.Get-started-btn-container button {

  width: 214px;
  height: 48px;
  /* UI Properties */
  background: #6423DB 0% 0% no-repeat padding-box;
  border-radius: 24px;
  color: white;
  font-family: "Link Sans";
  font-size: 18px;
  border: 0px;
  font-weight: bold;

}


.Partner-Sec2 {

  display: flex;
  flex-direction: row;
  column-gap: -2%;
}

.iphone-container {
  position: relative;
  width: 620px;
  /* Match the width of the iPhone image */
  height: 555px;
  /* Match the height of the iPhone image */
}

.iphone-image {
  width: 609px;
  /* Adjust to container size */
  height: 113%;
  margin-left: -12rem;
}

.video-container {
  position: absolute;
  top: 9%;
  /* Adjust based on the position of the screen within the iPhone */
  left: 31%;
  /* Adjust based on the position of the screen within the iPhone */
  width: 76%;
  /* Adjust to fit the screen area of the iPhone */
  height: 89%;
  /* Adjust to fit the screen area of the iPhone */
  z-index: -1;
  /* Ensure the video is on top of the image */
}

video {
  width: 225px;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  /* To match the screen corners */
  overflow: hidden;
  margin-left: -8.7rem;

}


.features-item-con {

  width: 250px;
  height: 250px;
  background: transparent linear-gradient(89deg, #4925E1 0%, #727274 100%) 0% 0% no-repeat padding-box;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 3rem;
  padding-top: 2.5rem;
}

.features-item-con img {

  width: 92px;
  height: 92px;

}

.features-item-con p {

  width: 152px;
  height: 93px;
  text-align: center;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
}

.swiper-container {
  max-width: 850px;
  /* Define a max-width for the Swiper container */
  margin: 0 auto;
  /* Center the container */
  width: 100%;


}

.swiper-wrapper {
height: 350px;
  /* Set to desired height */
padding-bottom: 4.4rem;
}



.swiper-button-next,
.swiper-button-prev {
  background: none;
  /* Remove default background */
  border: none;
  /* Remove border */
  color: transparent;
  /* Make the arrow icons invisible */
  
}

/* Optional: Remove arrow icons completely */
.swiper-button-next::after,
.swiper-button-prev::after {
  content: none;
}

.swiper-button-next {
  background-image: url('/webapps/tippytap/src/Assets/chevron-down@2x.png');
  /* Replace with your image path */
  background-size: 15px 15px;
  /* Width and height of the image */
  background-repeat: no-repeat;
  background-position: center;
  /* Centers the image within the button */
  background-color: #4B28DD;
  border-radius: 24px;
  margin-top: 7.5rem;
  width: 50px !important;
  height: 50px !important;

}

.swiper-button-prev {
  background-image: url('/webapps/tippytap/src/Assets/chevron-down@2x.png');
  /* Replace with your image path */
  background-repeat: no-repeat;
  background-position: center;
  /* Centers the image within the button */
  background-color: #A8A8A8;
  background-size: 15px 15px;
  width: 50px !important;
  height: 50px !important;
  border-radius: 24px;
  margin-top: 7.5rem;

}

.swiper-first-container {}

@media (max-width: 480px) {


  .flex-features {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
  }

  .Left-img-features img {

    width: 100%;
    height: 100%;

  }

  .Partner-Sec2 {

    display: flex;
    flex-direction: column-reverse;
    column-gap: -2%;
  }


  .features-item-con {

    width: 250px;
    height: 250px;
    background: transparent linear-gradient(89deg, #4925E1 0%, #727274 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 0rem;
  }

  .swiper-first-container {

    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: -6rem;
  }

  .swiper-container {

    margin-top: 2rem;
  }

  .swiper-wrapper {

    height: 500px;
    /* Set to desired height */

  }

  .iphone-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100%;
    height: auto;
    /* Adjust container height to fit content */
    padding: 0;
    margin: 0;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: -7rem;
  }

  .iphone-image {
    width: 100%;
    /* Set width as a percentage to make it responsive */
    /* Adjust to container size */
    height: auto;
    margin-left: 0px;
    margin-right: 0px;
  }



  .video-container {
    position: absolute;
    top: 7.6%;
    /* Adjust based on the position of the screen within the iPhone */
    left: 26.9999999%;
    z-index: -1;
    /* Ensure the video is on top of the image */
  }

  video {
    width: 49%;
    height: auto;
    border-radius: 20px;
    /* To match the screen corners */
    overflow: hidden;
    margin-left: 1rem;

  }

  .swiper-button-next {
   
    margin-top: 5rem;
    
  
  }
  
  .swiper-button-prev {

    margin-top: 5rem;
  
  }


}


@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .Partner-Sec2 {

    display: flex;
    flex-direction: column;
    column-gap: 2%;

  }

  .iphone-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100%;
    height: auto;
    /* Adjust container height to fit content */
    padding: 0;
    margin: 0;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0rem;
    clear: both;
  }

  .text-key-features {

    margin-top: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

  }

  .features-item-con {

    width: 200px;
    height: 230px;

  }

  .iphone-image {
    width: 90%;
    /* Set width as a percentage to make it responsive */
    /* Adjust to container size */
    height: auto;
    margin-left: 0px;
    margin-right: 0px;
  }

  .video-container {
    position: absolute;
    top: 8%;
    /* Adjust based on the position of the screen within the iPhone */
    left: 27.9%;
    z-index: -1;
    /* Ensure the video is on top of the image */
  }

  video {
    width: 43.5%;
    height: auto;
    border-radius: 20px;
    /* To match the screen corners */
    overflow: hidden;
    margin-left: 2.44444rem;

  }

}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait),
only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {

  .iphone-image {
    width: 90%;
    /* Set width as a percentage to make it responsive */
    /* Adjust to container size */
    height: auto;
    margin-left: 0px;
    margin-right: 0px;
  }

  .video-container {
    position: absolute;
    top: 8%;
    /* Adjust based on the position of the screen within the iPhone */
    left: 27.9%;
    z-index: -1;
    /* Ensure the video is on top of the image */
  }

  video {
    width: 43.5%;
    height: auto;
    border-radius: 20px;
    /* To match the screen corners */
    overflow: hidden;
    margin-left: 3.3rem;

  }

}