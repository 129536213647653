.first-cell-label {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 34.28px;
    text-align: left;
  }
  
  .link {
    font-family: Poppins;
    font-size: 14.04px;
    font-weight: 500;
    line-height: 28.56px;
    color: #4a68d9;
  }
  
  .icon-large {
    font-size: 24px; /* Adjust the size as needed */
    margin-top: -0.4rem;
  }
  
  .Add-new-table-row {
    width: 130px;
    height: 41.89px;
    border-radius: 8.57px;
    box-shadow: 0px 0px 3.81px 0px #00000040;
    background: #ffffff;
    font-family: Poppins;
    font-size: 16.85px;
    font-weight: 500;
    line-height: 34.28px;
    color: #000000;
    border: 0px;
  }
  
  .title-btn-collapse {
    background-color: transparent;
    border: 0px;
    font-family: Poppins;
    font-size: 19.85px;
    font-weight: 500;
    line-height: 34.28px;
  }
  
  .card-header {
    /* background-color: #F0F0F0;  */
  }
  
  /* Optional: If you want to customize button styles further */
  .title-btn-collapse {
    background: none;
    border: none;
    color: inherit;
  }
  
  .custom-dropdown1 {
    appearance: auto; /* Ensures the native appearance */
    width: 100%; /* Adjust width as needed */
    border: 1px solid #ced4da; /* Border styling */
    border-radius: 0.25rem; /* Border radius */
    padding: 0.375rem 0.75rem; /* Padding */
  }
  
  .custom-dropdown::-ms-expand {
    display: none; /* Hide the default arrow for older IE versions if needed */
  }
  
  .Personal-table td,
  .Personal-table th {
    border: 1px solid #dee2e6; /* Border color */
  }
  
  /* Optional: Add padding and vertical alignment for better spacing */
  .Personal-table td,
  .Personal-table th {
    padding: 0.75rem; /* Adjust padding as needed */
    vertical-align: top; /* Vertical alignment */
  }
  
  /* Optional: Add custom styles for dropdown */
  .Personal-table .custom-dropdown1 {
    width: 100%; /* Ensure select takes full width of its cell */
  }
  .Personal-table {
    table-layout: fixed; /* Ensures consistent cell widths */
    width: 100%;
  }
  
  .Personal-table td {
    width: 33.33%; /* Distribute space equally among three cells */
    padding: 8px; /* Optional: Adjust padding for better spacing */
    box-sizing: border-box; /* Ensure padding does not affect width */
  }
  
  .custom-dropdown1 {
    width: 100%; /* Ensure dropdown takes full width of its cell */
    box-sizing: border-box; /* Ensure padding/border do not affect width */
    background-color: transparent;
  }
  .label-style{
  
  display: flex;
  justify-content: start;
  padding-top: 0.4rem;
  
  }
  
  .form-group{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1%;
  
  }
  
  .label-data-1{
  
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 34.28px;
  
  
  }
  
  .Card-header-img{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;  
  }
  
  .custom-select-wrapper {
    position: relative;
  }
  
  .custom-select-no-arrow {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    padding-right: 20px; /* Optional: adjust for padding */
  }
  
  .custom-select-no-arrow::-ms-expand {
    display: none;
  }
  
  .editable-input {
    background-color: #F0F0F0; /* Light grey background for editable inputs */
  }
  
  .input-and-remove-flex{
  
    display: flex;
    column-gap: 2%;
  
  }
  @media (max-width: 480px) {
  
    .form-group{
      display: flex;
      flex-direction: column;
      grid-template-columns: 1fr 1fr;
      column-gap: 1%;
    
    }
  
  .Card-header-img{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;  
    width: 200px ;
  }
  
    
  }