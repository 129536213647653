.upload-profile-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.Card-header-img2{
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 36px;  
  }


.image-container {
    position: relative;
    width: 100px;
    height: 100px;
}

.camera-img {
    cursor: pointer;
    border-radius: 50%; /* Make the image circular */
    width: 100px; /* Ensure the image fits the container */
    height: 100px !important;
    object-fit: cover; /* Ensures the image covers the entire container */
    border: 0px !important;
    margin-top: 0.2rem;
}

.camera-img.has-border {
    border: 2px solid #000; /* Add border if image exists */
}

.edit-icon {
    position: absolute;
    right: 5px;
    bottom: 5px;
    cursor: pointer;
    background-color: white;
    font-size: 16px;
}
