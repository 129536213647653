@font-face {
    font-family: 'FrutigerLTArabic';
    src: url('../fonts/FrutigerLTArabic-65Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FrutigerLTArabic';
    src: url('../fonts/FrutigerLTArabic-55Roman.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}




.black-wrap{
    background-color: black;

}

.Logos-grid{

display: grid;
grid-template-columns: 1fr 1fr;

}

.aution-card-container-2{

    width: 92%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid #665f57;
    margin-bottom: 1rem;
}

.img-and-title-auction-card{

    display: grid;
    grid-template-columns: 1fr 1fr;
}

.images-auction-card2 img {

    width: 180px;
    height: 220px;
    
}

.titles-auction-card2{

    color: white;
    direction: rtl;
    margin-top: 3rem;
    
}

.titles-auction-card2 h6 ,h5{

   
    font-size: 26px;
    padding-right: 1rem;
    
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.signle-brown-data2 {

    display: flex;
    flex-direction: row-reverse;
    column-gap: 10%;
    border-bottom: 1px solid #665f57;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0;
    direction: rtl;

}

.signle-brown-data2 h4 {
    color: white;
    font-size: 25px;
    font-weight: normal;
    font-family: "Lateef", serif;
    margin-top:0.4rem;

}

.signle-brown-data2 p {

    color: white;
    font-size: 26px;
    font-family: "Lateef", serif;
    font-weight: bold;
    margin-bottom: 0rem;



}

.Logos-grid{
display: flex;
justify-content: center;
align-items: center;
padding: 3rem;
column-gap: 5%;

}

.logo-adihex-1{

border-right: 0px solid #665f57;
padding-right: 1rem;

}
.logo-adihex-2 img{

width: 180px !important;

}

.logo-adihex-2 {

    margin-left: auto;
    
    }









































.brown-titles {
    display: flex;
    /* Enables flexbox layout */
    justify-content: space-between;
    /* Distributes space between the text and image */
    align-items: center;
    flex-direction: row-reverse;
    /* Reverses the order of the flex items */
    /* Vertically centers the text and image */
}

.brown-titles h4 {
    display: inline-block;
    /* Ensures the border is only as wide as the text */
    color: #fafafa;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 20px;
    padding: 0.7rem;
    border-bottom: 3px solid #746d63;
    direction: rtl;
    /* Ensures the text direction is right-to-left */
    text-align: right;
    margin-right: 1.1rem;
    font-family: "Lateef", serif;

}



.brown-titles img {
    margin-right: auto;
    /* Pushes the image to the left side */
    width: 50px;
    height: 50px;
    margin-top: -10px;
    margin-left: 1rem;
}

.img-falcon-brown-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-falcon-brown-container img {

    width: 400px;
    margin-top: 3rem;
    border-radius: 8px;


}


.capture-whole{

    background-color: #231f20;

}
.New-Brown-Profile-container {

    background-color: #231f20;
}


.flex-brown-data {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    justify-content: center;

}

.signle-brown-data {

    display: flex;
    flex-direction: row-reverse;
    column-gap: 50%;
    border-bottom: 1px solid #665f57;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 0;
    direction: rtl;

}

.signle-brown-data h4 {
    color: white;
    font-size: 30px;
    font-weight: normal;
    font-family: "Lateef", serif;

}

.signle-brown-data p {

    color: white;
    font-size: 26px;
    font-family: "Lateef", serif;
    font-weight: bold;


}

.Download-card-container {

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    padding-bottom: 1rem;

}

.Download-Card-btn {

  width: auto;
  border-radius: 10px;

  height: auto;
  padding-top: 0.2rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
 border: 0px;
  font-size: 24px;
  font-family: "Lateef", serif;
  font-weight: bold;
  color: #fafafa;
  background-color: #85754e	 ;

}

.Download-Card-btn:hover {
    background: #c5b358	 !important; /* Change this to your desired color */
    color: white !important; /* Optional: Change the text color as well */
}


/* Newwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww- layout */

.black-wrap{
    background-color: #000000;

}

.Logos-grid{

display: grid;
grid-template-columns: 1fr 1fr;

}


@media (max-width: 480px) {
    .img-falcon-brown-container img {

        width: 200px;
        border-radius: 8px;
    }

    .img-falcon-brown-container {
        display: flex;
        justify-content: center;
        align-items: center;

    }



    .flex-brown-data {
        margin-top: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .signle-brown-data {

        display: flex;
        grid-template-columns: 1fr 1fr;
        column-gap: 25%;
        padding: 1rem;
        border-bottom: 1px solid #665f57;
        clear: both;
    }

    .signle-brown-data h4,
    p {

        color: white;
        font-size: 28px;
        font-weight: normal;

    }

    .signle-brown-data h4 {
        width: 150px;

    }

    .signle-brown-data p {

        color: white;
        font-size: 27px;
        font-family: "Lateef", serif;
        font-weight: bold;
        width: 100px;
        text-align: left;
        margin-top: 0.5rem;
    }
    /* Newwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww- layout */



    .black-wrap{
        background-color: black;
    
    }
    
    .Logos-grid{
    
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    }

    .aution-card-container-2{

        width: 92%;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #665f57;
        margin-bottom: 1rem;
    }

    .img-and-title-auction-card{

        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .images-auction-card2 img {

        width: 180px;
        height: 220px;
        
    }

    .titles-auction-card2{

        color: white;
        direction: rtl;
        margin-top: 3rem;
        
    }

    .titles-auction-card2 h6 ,h5{

       
        font-size: 26px;
        padding-right: 1rem;
        
    }

    .flex-center{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .signle-brown-data2 {

        display: flex;
        flex-direction: row-reverse;
        column-gap: 10%;
        border-bottom: 1px solid #665f57;
        justify-content: space-between;
        align-items: center;
        padding: 0.1rem 0;
        direction: rtl;
    
    }
    
    .signle-brown-data2 h4 {
        color: white;
        font-size: 25px;
        font-weight: normal;
        font-family: "Lateef", serif;
        margin-top:0.4rem;
    
    }
    
    .signle-brown-data2 p {
    
        color: white;
        font-size: 26px;
        font-family: "Lateef", serif;
        font-weight: bold;
        margin-bottom: 0rem;
        text-align: left;
    
    
    }

  .Logos-grid{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    column-gap: 3%;

  }

.logo-adihex-1{

    border-right: 1px solid #665f57;
    padding-right: 1.5rem;
    
}



.logo-adihex-1 img{

    width: 150px !important;
     
 }
.logo-adihex-2 img{

   width: 150px !important;
    
}

}


@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .black-wrap{
        background-color: black;
        height: 100vh;
    
    }
    
    .Logos-grid{
    
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    }

    .aution-card-container-2{

        width: 92%;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #665f57;
        margin-bottom: 1rem;
    }

    .img-and-title-auction-card{

        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .images-auction-card2 img {

        width: 180px;
        height: 220px;
        
    }

    .titles-auction-card2{

        color: white;
        direction: rtl;
        margin-top: 3rem;
        
    }

    .titles-auction-card2 h6 ,h5{

       
        font-size: 26px;
        padding-right: 1rem;
        
    }

    .flex-center{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .signle-brown-data2 {

        display: flex;
        flex-direction: row-reverse;
        column-gap: 10%;
        border-bottom: 1px solid #665f57;
        justify-content: space-between;
        align-items: center;
        padding: 0.1rem 0;
        direction: rtl;
    
    }
    
    .signle-brown-data2 h4 {
        color: white;
        font-size: 25px;
        font-weight: normal;
        font-family: "Lateef", serif;
        margin-top:0.4rem;
    
    }
    
    .signle-brown-data2 p {
    
        color: white;
        font-size: 26px;
        font-family: "Lateef", serif;
        font-weight: bold;
        margin-bottom: 0rem;

    
    
    }

  .Logos-grid{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    column-gap: 3%;

  }

.logo-adihex-1{

    border-right: 0px solid #665f57;
    padding-right: 4rem;
    
}
.logo-adihex-2 img{

   width: 180px !important;
    
}



}


@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .black-wrap {

        height: 120vh;
    }
}

/* Media query for iPad Pro in both portrait and landscape modes */
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
    .black-wrap {

        height: 100vh;
    }

}


@media only screen and (min-width: 912px) and (max-width: 912px) and (min-height: 1368px) and (max-height: 1368px) {
    .black-wrap {

        height: 100vh;
    }

}













@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .New-Brown-Profile-container {

        height: auto;
    }
}

/* Media query for iPad Pro in both portrait and landscape modes */
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
    .New-Brown-Profile-container {

        height: 100vh;
    }

}


@media only screen and (min-width: 912px) and (max-width: 912px) and (min-height: 1368px) and (max-height: 1368px) {
    .New-Brown-Profile-container {

        height: 100vh;
    }

}