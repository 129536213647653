.bluenav {
  background-color: #6423DB;
  position: fixed;
  /* Fixes the position to the top of the viewport */
  top: 0;
  /* Aligns it to the top */
  left: 0;
  /* Aligns it to the left */
  z-index: 100;
  /* Ensure it's above the navbar */
  width: 100%;
  height: 50px;
}

.blue-nav-paragraph {
  display: flex;
  justify-content: center;
  text-align: center;
}

.blue-nav-paragraph h6 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  color: #FFFFFF;
  padding-top: 1rem;
}


@media (max-width:480px) {

  .blue-nav-paragraph h6 {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 700;
    line-height: 19.5px;
    color: #FFFFFF;
    padding: 0.7rem;
    padding-top: 0.3rem;
    margin: 0px;
  }

  .bluenav {
    height: 40px;
    background-color: #6423DB;
  }

  .navbar-logo {}



}

@media only screen and (min-width: 768px) and (max-width: 1024px) {}