.falcons-container {
  width: 100%;
  height: 100%;
  border-radius: 6.13px;
  box-shadow: 0px 1px 7.3px 2px rgba(217, 217, 217, 0.87);
  background: rgba(255, 255, 255, 1);
  padding: 2rem;
  margin-top: 10rem;
  margin-bottom: 2rem;
  
}

.falcons-container h6 {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.single-falcon-detail {
  margin-bottom: 2rem;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 2.91px 0.48px rgba(217, 217, 217, 0.87);
  padding: 2rem;
}

.single-falcon-detail-titles {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.single-falcon-detail-titles h6 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: rgba(0, 0, 0, 1);
}

.edit-btn .edit-btn-001 {
  width: 82.37px;
  height: 28.1px;
  border: 0px;
  border-radius: 8.72px;
  background: rgba(248, 248, 248, 1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.17);

  font-family: Poppins;
  font-size: 15.5px;
  font-weight: 400;
  line-height: 23.26px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}
.edit-btn .edit-btn-002 {
  width: 82.37px;
  height: 28.1px;
  border: 0px;
  border-radius: 8.72px;
  background: rgba(248, 248, 248, 1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.17);

  font-family: Poppins;
  font-size: 15.5px;
  font-weight: 400;
  line-height: 23.26px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}
.view-btn {
  width: 82.37px;
  height: 28.1px;
  border: 0px;
  border-radius: 8.72px;
  background: rgba(248, 248, 248, 1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.17);
  margin-right: 10px;
}
.modal-close-btn{


  position: absolute;
  top: 0.8rem; /* Adjust as needed */
  right: 1.2rem; /* Adjust as needed */
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10; /* Ensure it's above other content */

}
.view-btn img {
  width: 25px;
  height: 15px;
  display: none;
}
.edit-btn {
  float: right;
}
.single-falcon-img-container {
  margin-right: 5%;
  display: flex;
  align-items: center; /* Align items to the top of the container */
  justify-content: center;
  width: 158px;
  height: 158px;
  overflow: hidden;
  position: relative;
}

.single-Profile-img-container {
  display: flex;
  align-items: center; /* Align items to the top of the container */
  justify-content: center;
  margin-right: 4%;
  margin-top: 2.2%;
}

.single-falcon-data-layout {
  display: flex;
}

.single-falcon-column-data-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: auto;
  padding-top: 0.2rem;
}
.falcon-data-1 {
  width: 300px;
  font-family: Poppins;
  font-size: 15.5px;
  font-weight: 400;
  line-height: 23.26px;
  color: rgba(0, 0, 0, 0.46);
}
.falcon-data-1 span {
  font-weight: 600;
}
.falcon-data-1 p {
  color: rgba(0, 0, 0, 0.46);
}

.edit-mode {
  margin-left: 1rem;
}

.editing-img-container-when-press {
  margin-right: 5%;
  /* width: 240px; */
}

/* .edit-mode input {
width: 200px;} */

.password-disc {
  font-family: Poppins;
  font-size: 15.5px;
  font-weight: 400;
  text-align: left;
  text-security: disc; /* For WebKit browsers */
  -webkit-text-security: disc; /* For Safari */
  -moz-text-security: disc; /* For Firefox */
  color: rgba(0, 0, 0, 0.46);
}

/* Optional: if the text-security property is not supported, you can use text masking */
.password-disc::before {
  content: "******"; /* Replace with the same number of characters as the password */
  color: rgba(0, 0, 0, 0.46);
}
.password-disc {
  color: transparent;
  color: rgba(0, 0, 0, 0.46);
}
.profile-img-001 {
  width: 158px;
  height: 158px;
  margin-top: 0%;
  border-radius: 8px;
}

.falcon-image-1-3 {
  border-radius: 6px;
  margin-right: 0%;
  object-position: top; /* Focus on the top part of the image */
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the container without distortion */
  transform: translate(-50%, -50%); /* Center the image */
}

.deleteicon-img {
  width: 40px;
  height: 40px;
}

.delete-btn-001 {
  width: 82.37px;
  height: 28.1px;
  border: 0px;
  border-radius: 8.72px;
  background-color: #f9e1e1;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.17);
  font-family: Poppins;
  font-size: 14.31px;
  font-weight: 500;
  text-align: center;
  color: #f96969;
  margin-left: 10px;
}

.Modal {
  background: white;
  padding: 20px;
  border-radius: 4px;
  width: 633px;
  height: auto;
  top: 636px;
  max-width: 500px;
  margin: auto; /* Center horizontally */
  position: relative;
  z-index: 1049;
  top: 50%;
  left: 14%;
  transform: translate(-50%, -50%); /* Center the modal */
  border-radius: 10px;
  padding: 2rem;
  border: 0px !important;
}
.Overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed; /* Ensure overlay covers the entire screen */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050; /* Must be less than the modal's z-index */
  border: 0px !important;

}

.AddModal {
  background: white;
  padding: 20px;
  border-radius: 4px;
  width: 633px;
  height: auto;
  top: 636px;
  max-width: 500px;
  margin: auto; /* Center horizontally */
  position: relative;
  z-index: 1049; /* Ensure it's above the overlay */
  top: 50%;
  left: 14%;
  transform: translate(-50%, -50%); /* Center the modal */
  border-radius: 10px;
  padding: 2rem;
}
.Overlay2 {
  background: rgba(0, 0, 0, 0.5);
  position: fixed; /* Ensure overlay covers the entire screen */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050; /* Must be less than the modal */
}

.AddModal h2 {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #000000;
}

.Modal h2 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin-bottom: 3rem;
  padding-top: 2rem;
}

.Modal2 h2 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin-bottom: 3rem;
  padding-top: 2rem;
}
.save-btn {
  background-color: #32de84 !important;
  color: white !important;
}
.model-btns-sec {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10%;
}

.delete-modal-btn {
  width: 202px;
  height: 42px;
  border-radius: 7.64px;
  background-color: #f9e1e1;

  font-family: Poppins;
  font-size: 16.4px;
  font-weight: 400;
  line-height: 24.6px;
  text-align: center;
  color: #f96969;
  border: 0px;
}

.cancel-modal-btn {
  width: 202px;
  height: 42px;
  border-radius: 7.64px;
  background-color: white;
  border: 1px solid #4a68d9;
  font-family: Poppins;
  font-size: 16.4px;
  font-weight: 400;
  line-height: 24.6px;
  text-align: center;
  color: #4a68d9;
}

.falcon-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.floating-btn {
  margin-left: auto;
}

.success-message p {
  font-family: Poppins;
  font-size: 18.4px;
  font-weight: 400;
  text-align: center;
}
.floating-btn button {
  width: auto;
  height: auto;
  border: 0px;
  padding: 0.5rem;
  border-radius: 8.72px;
  background: rgba(248, 248, 248, 1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.17);
  font-family: Poppins;
  font-size: 15.5px;
  font-weight: 400;
  line-height: 23.26px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 1rem;
}

.error-message-falcon {
  margin-top: 0.5rem;
  font-size: 14px !important;
  color: rgba(229, 73, 73, 1);
}

.pin-img {
  justify-content: center;
  align-items: center;
  margin-right: 2px;
}

.image-cropper {
  position: relative;
}

.crop-controls {
  display: flex;
  justify-content: space-between;
}

.crop-controls button {
  margin-top: 10px;
}

.btns-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  column-gap: 5%;
}

.delete-btn-profile {
  width: 170px;
  height: 30px;
  border: 0px;
  border-radius: 8.72px;
  background-color: #f9e1e1;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.17);
  font-family: Poppins;
  font-size: 14.31px;
  font-weight: 500;
  text-align: center;
  color: #f96969;
}

.edit-btn-profle {
  width: 170px;
  height: 30px;
  border: 0px;
  border-radius: 8.72px;
  background: rgba(248, 248, 248, 1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.17);
  font-family: Poppins;
  font-size: 14.5px;
  font-weight: 400;
  line-height: 23.26px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}

@media (max-width: 480px) {
  .delete-btn-001 {
    margin-left: 0px;
  }

  .floating-btn {
    margin-left: none;
  }
  .view-btn img {
    width: 25px;
    height: 15px;
    display: block;
  }

  .view-btn {
    /* width: 40.37px;
    height: 28.1px; */
  }
  .falcons-container {
    width: 95%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8rem;
    margin-bottom: 2rem;
    padding: 1rem;
  }

  .profile-img-001 {
    width: 158px;
    height: 138px;
    margin-top: 1%;
    border-radius: 8px;
  }

  .falcon-data-1 {
    width: 100%;
    font-family: Poppins;
    font-size: 15.5px;
    font-weight: 400;
    line-height: 23.26px;
    color: rgba(0, 0, 0, 0.46);
    padding-top: 1rem;
  }

  .single-falcon-detail {
    margin-bottom: 2rem;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 2.91px 0.48px rgba(217, 217, 217, 0.87);
    padding: 1rem;
  }

  .single-falcon-column-data-container {
    display: flex;
    flex-direction: column;
    column-gap: auto;
    padding-top: 0.2rem;
  }

  .single-falcon-data-layout {
    display: flex;
    flex-direction: column;
  }

  .single-falcon-img-container {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 2%;
    margin-right: 0%;
    width: 100%;
  }

  .edit-mode {
    margin-left: 0rem;
  }
  .edit-btn {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .falcon-data-1 p {
    color: rgba(0, 0, 0, 0.46);
    margin-bottom: 0px;
    font-size: 15px;
  }
  .profile-img-001 {
    width: 200px;
    height: 160px;
  }
  .falcon-image-1-3 {
    width: 200px;
    height: 160px;
    border-radius: 6px;
    object-fit: cover;
    margin: 0px;
    justify-content: center;
  }

  .hide-text-mobile {
    display: none;
  }
  .edit-btn .edit-btn-001 {
    /* width: 30px;
    height: 28.1px; */
  }

  .pin-img {
    justify-content: center;
    align-items: center;
    margin-right: 0px;
  }

  .form-conrol {
    width: 100% !important;
  }

  .Modal {
    width: calc(100% - 40px); /* 20px margin on each side */
    height: auto;
    padding: 2rem; /* Adjust padding for mobile view */
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%); /* Center the modal */
  }

  .AddModal {
    width: calc(100% - 40px); /* 20px margin on each side */
    height: 550px;
    padding: 2rem; /* Adjust padding for mobile view */
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%); /* Center the modal */
    overflow-y: auto;
    margin-top: 1rem;
  }
  .Modal h2 {
    margin-bottom: 1rem;
  }

  .model-btns-sec {
    margin-bottom: 1rem;
    margin-top: 2rem;
    column-gap: 15%;
  }
  .Overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed; /* Ensure overlay covers the entire screen */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999; /* Must be less than the modal's z-index */
  }
  .delete-modal-btn {
    width: 100%;
  }

  .cancel-modal-btn {
    width: 100%;
  }

  .center-input-choose input {
    width: 105% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .falcon-data-1 {
    width: 100%;
    font-family: Poppins;
    font-size: 15.5px;
    font-weight: 400;
    line-height: 23.26px;
    color: rgba(0, 0, 0, 0.46);
    padding-top: 1rem;
    
  }
  .view-btn img {
    display: none;
  }
  .falcons-container {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12rem;
    margin-bottom: 2rem;
    padding: 1rem;
  }
  .single-falcon-column-data-container {
    display: flex;
    flex-direction: column;
    column-gap: auto;
    padding-top: 0.2rem;
    
  }
  .falcon-data-1 p {
    font-size: 15px;
  }
  .single-falcon-data-layout {
    display: flex;
    flex-direction: column;
  }

  .single-falcon-img-container {
    margin-top: 2%;
    margin-right: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile-img-001 {
    width: 200px;
    height: 160px;
  }
  .falcon-image-1-3 {
    width: 200px;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
    margin: 0px;
    justify-content: center;
  }
  .single-falcon-img-container {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 2%;
    margin-right: 0%;
    width: 100%;
    

  }
  .Modal {
    width: calc(100% - 40px); /* 20px margin on each side */
    height: auto;
    padding: 2rem; /* Adjust padding for mobile view */
    top: 50%;
    left: 33%;
    transform: translate(-50%, -50%); /* Center the modal */
  }
}
