.flex-Welcomesec {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1%;
  padding-top: 10rem;
  padding-bottom: 2rem;
}

.Right-text {
  padding-top: 6rem;
}

.Right-text h6 {
  font-family: "Rowdies", sans-serif;
  font-weight: 700;
  font-style: normal;
    font-weight:bold;
  /* UI Properties */
  text-align: left;
  color: #202020;
  font-size: 44px;
}

.Right-text h6 span {

  color: #4925E1;
}

.Right-text h5 {
  font-family: "Rowdies", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 35.94px;
  text-align: left;
  color: #000000;
}

.Right-text h4 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 23.96px;
    text-align: left;
}

.Right-text p {
  font-family: "Rowdies", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 28.45px;
    text-align: left;
    color:
    #6B6B6B;  
}

.welcome-sec-btn button {

width: 170px;
height:44px;
border-radius: 9px ;
opacity: 0px;
border: 0px;
color: white;
background-color: rgba(74, 104, 217, 1);
font-family: Poppins;
font-size: 17px;
font-weight: 500;
line-height: 25.46px;

}

.Left-img-welcome-sec{

  margin-left: 0rem;
}

.Left-img-welcome-sec img{

  width: 100%;
  height: auto;
  margin-top: 2rem;
}

@media (max-width: 480px) {

  .flex-Welcomesec {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 1rem;
  }

  .Left-img-welcome-sec{

    margin-top: 1.5rem;
  }

  .Welcomesec{
    padding-top: 5rem;
  }

  .Left-img-welcome-sec img{

    width: 100%;
    height: auto;
    margin-top: 0rem;
  }

  .Right-text {
    padding-top: 0rem;
    margin-top: 3rem;
  }
  
  
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .Right-text h6 {
    font-family: "Rowdies", sans-serif;
    font-weight: 700;
    font-style: normal;
      font-weight:bold;
    /* UI Properties */
    text-align: left;
    color: #202020;
    font-size: 30px;
  }

  .Right-text {
    padding-top: 3rem;
    margin-top: -2rem;
  }

  .Left-img-welcome-sec img{

    width: 100%;
    height: 100%;
    margin-top: -1.2rem;
  }
}