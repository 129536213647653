.Checkout-container {
    width: 1238px;
    height: 981px;
    border-radius: 9px 0px 0px 0px;
    box-shadow: -1px 0px 6.4px 0.5px #d9d9d9de;
    margin-bottom: 3rem;
  
  }
  
  .checkout-header {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #000000;
    margin-top: 9rem;
    margin-bottom: 1rem;
  }
  
  .card-order {
    display: flex;
    flex-direction: column !important;
    row-gap: 5%;
  }
  
  .flex-checkout-layout {
    display: flex;
    /* flex-direction: column; */
    column-gap: 10%;
    padding-top: 3rem;
  }
  
  .checkoutcard {
    display: flex;
    column-gap: 2%;
    box-shadow: 0px 4px 14.4px 0px #60606040;
    width: 650px;
    height: 259px;
    border-radius: 9px ;
    padding: 1.4rem;
    margin-bottom: 1rem;
  }
  
  .Ecard-img {
    width: 312.47px;
    height: 200.08px;
    border-radius: 9px 0px 0px 0px;
  }
  
  .tippy-card-details {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .tippy-card-details h6 {
    font-family: Poppins;
    font-size: 23.71px;
    font-weight: 600;
    line-height: 45.05px;
    margin: 0px;
  }
  
  .tippy-card-details span {
    font-family: Poppins;
    font-size: 17.78px;
    font-weight: 400;
    line-height: 33.79px;
    color: #00000075;
    margin-bottom: 0.2rem;
  }
  
  .btns-quantity {
    display: flex;
    column-gap: 6%;
  }
  
  .btns-quantity span {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 20.94px;
    color: #000000;
    margin-top: 5px;
  }
  
  .btns-quantity button {
    border: 0px;
    background-color: #6423DB;
    width: 30.68px;
    height: 30.68px;
    color: white;
    border-radius: 50%;
  }
  
  .btn-relative {
    position: relative;
  }
  
  .trueicon-agree{
  
    margin-right: 0.2rem;
  
  }
  
  
  .close-btn {
    position: absolute;
    top: -8%;
    /* Adjust as needed */
    left: 37rem;
    /* Adjust as needed */
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: black;
    /* Change color as needed */
  }
  
  .btns-quantity p {
    font-family: Poppins;
    font-size: 19px;
    font-weight: 500;
    line-height: 28.45px;
    color: #8a8a8a;
  }
  
  .checkout-process {
    height: 871px;
    border-radius: 9px 0px 0px 0px;
    box-shadow: -1px 2px 9.2px 0px #69696940;
    padding: 2rem;
  }
  
  .order-details {
    display: flex;
    flex-direction: column;
  }
  
  .checkout-process h6 {
    margin-bottom: 1rem;
  }
  
  .order-details .detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .line-blue img {
    margin-bottom: 3rem;
  }
  
  .order-details .detail span {
    font-family: Poppins;
    font-size: 17.15px;
    font-weight: 550;
    line-height: 25.73px;
    margin-bottom: 0.2rem;
  }
  
  .checkout-btn {
    margin-top: 1rem;
    height: 44px;
    border-radius: 9px;
    background: #6423DB;
    color: white;
    border: 0px;
  }
  
  .doyouagree span {
  
    margin-left: 5px;
  
  }
  
  @media (max-width: 480px) {
    .Checkout-container {
      width: 90%;
      height: 100%;
      border-radius: 9px 0px 0px 0px;
      box-shadow: -1px 0px 6.4px 0.5px #d9d9d9de;
    }
  
    .checkout-header {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: #000000;
      margin-top: 8rem;
      margin-bottom: 1rem;
    }
  
    .Checkout-container {
      margin-top: 1rem;
    }
  
    .flex-checkout-layout {
      display: flex;
      /* flex-direction: column; */
      flex-direction: column;
      /* column-gap: 25%; */
    }
  
    .checkoutcard {
      display: flex;
      column-gap: 3%;
      width: 100%;
      height: 100%;
      padding: 0rem;
    }
  
    .Ecard-img {
      width: 172.47px;
      height: 100%;
      padding: 1rem;
      border-radius: 9px 0px 0px 0px;
      padding-left: 0px;
    }
  
    .checkout-process {
      height: 100%;
      border-radius: 9px 0px 0px 0px;
      box-shadow: -1px 2px 9.2px 0px #69696940;
      padding: 2rem;
    }
  
    .tippy-card-details h6 {
      font-family: Poppins;
      font-size: 17.71px;
      font-weight: 600;
    }
  
    .tippy-card-details span {
      font-family: Poppins;
      font-size: 15.78px;
      font-weight: 400;
      line-height: 33.79px;
      color: #00000075;
      margin-bottom: 0.3rem;
    }
  
    .btns-quantity {
      display: flex;
      column-gap: 10%;
    }
  
    .btns-quantity span {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      line-height: 20.94px;
      color: #000000;
      margin-top: 5px;
    }
  
    .btns-quantity button {
      border: 0px;
      background-color: #6423DB;
      width: 30.68px;
      height: 30.68px;
      color: white;
      border-radius: 50%;
      margin-top: 0rem;
      font-size: 12px;
  
    }
  
    .line-blue img {
      margin-bottom: 3rem;
      width: 100%;
    }
  
    .close-btn {
      position: absolute;
      top: 1%;
      /* Adjust as needed */
      left: 10rem;
      /* Adjust as needed */
      border: none;
      font-size: 14px;
      cursor: pointer;
      color: black;
      /* Change color as needed */
      display: none;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .Checkout-container {
      margin-top: 1rem;
      margin-bottom: 1rem;
      height: 100%;
    }
  
    .flex-checkout-layout {
      display: flex;
      /* flex-direction: column; */
      flex-direction: column;
      /* column-gap: 25%; */
    }
  
    .checkout-process {
      height: auto;
      border-radius: 9px 0px 0px 0px;
      box-shadow: -1px 2px 9.2px 0px #69696940;
      padding: 2rem;
      margin-bottom:3rem;
    }
    .checkoutcard {
      display: flex;
      column-gap: 10%;
      width: 100%;
      justify-content: center;
  
    }
  
    .Ecard-img {
      width:220px;
      height: 100%;
      border-radius: 9px 0px 0px 0px;
    }
  
    .close-btn {
      position: absolute;
      top: 1%;
      /* Adjust as needed */
      left: 40rem;
      /* Adjust as needed */
      border: none;
      font-size: 20px;
      cursor: pointer;
      color: black;
      /* Change color as needed */
    }
  
    .line-blue img {
      margin-bottom: 3rem;
      width: 100%;
    }
  }