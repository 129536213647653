.Business-content h6 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #000000;
    margin-bottom: 1rem;
  }
  
  .BussinessCard-container {
    width: 820px;
    height: 100%;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 0px 2.91px 0.48px #d9d9d9de;
    padding: 3rem;
  }
  
  .BussinessCard-container-float {
    display: flex; /* Enable Flexbox */
    justify-content: space-between; /* Distribute space between items */
    align-items: center; /* Align items vertically */
  }
  
  .order-btn-container {
    margin-left: auto; /* Pushes the button container to the right */
  }
  
  .order-now-btn {
    width: 166px;
    height: 44px;
    border-radius: 8px;
    border: 0px;
    background: #4a68d9;
    font-family: Poppins;
    font-size: 17px;
    font-weight: 500;
    line-height: 25.46px;
    color: #ffffff;
    box-shadow: 0px 0px 4px 0px #0000002b;
    margin-bottom: 1rem;
  }
  
  .blue-container {
    background: #e8edff;
    display: grid;
    flex-direction: row;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: flex-start;
    column-gap: 5%;
    padding: 0.5rem;
    padding-top: 1rem;
    border-radius: 10px;
    margin-top: 1rem;
  }
  .order-number-001{
  
    width: 130px;
  
  } 
  
  .blue-container-data-flexing {
    display: flex;
    cursor: pointer;
  }
  
  .new-btn {
    width: 106.07px;
    height: 30.82px;
    background-color: #69b852;
    font-family: Poppins;
    border: 0px;
    font-size: 14.06px;
    font-weight: 500;
    line-height: 21.08px;
    color: #ffffff;
    border-radius: 13.6px;
  }
  
  .blue-container-data-flexing span {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #4a68d9;
  }
  
  .blue-container-data-flexing p {
    color: #4a68d9;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
  
  
  
  @media (max-width: 480px) {
    .BussinessCard-container {
      width: 100%;
      height: 100%;
      padding: 0.5rem;
    }
  
    .order-now-btn {
      width: 100%;
      height: 44px;
      border-radius: 8px;
      border: 0px;
      background: #4a68d9;
      font-family: Poppins;
      font-size: 12px;
      padding: 0.3rem;
      font-weight: 500;
      line-height: 25.46px;
      color: #ffffff;
      box-shadow: 0px 0px 4px 0px #0000002b;
    }
  
    .blue-container {
      background: #e8edff;
      display: flex;
      flex-direction: column;
      column-gap: 1rem;
      padding: 0.5rem;
      padding-top: 1rem;
      border-radius: 10px;
      margin-top: 1rem;
    }
  }
  
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
  
    .BussinessCard-container {
      width: 100% !important;
      height: 100%;
      border-radius: 8px;
      background: #ffffff;
      box-shadow: 0px 0px 2.91px 0.48px #d9d9d9de;
      padding: 0.5rem;
    }
    
    .order-now-btn {
      width: 100%;
      height: 44px;
      border-radius: 8px;
      border: 0px;
      background: #4a68d9;
      font-family: Poppins;
      font-size: 12px;
      padding: 0.3rem;
      font-weight: 500;
      line-height: 25.46px;
      color: #ffffff;
      box-shadow: 0px 0px 4px 0px #0000002b;
    }
    .blue-container {
      background: #e8edff;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: flex-start;
      column-gap: 3%;
      padding: 0.2rem;
      padding-top: 1rem;
      border-radius: 10px;
      margin-top: 1rem;
    }
    .new-btn {
      width: 60.07px;
      height: 30.82px;
      background-color: #69b852;
      font-family: Poppins;
      border: 0px;
      font-size: 14.06px;
      font-weight: 500;
      line-height: 21.08px;
      color: #ffffff;
      border-radius: 13.6px;
    }
  
    .blue-container-data-flexing span {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      color: #4a68d9;
    }
    
    .blue-container-data-flexing p {
      color: #4a68d9;
      font-family: Poppins;
      font-size: 9px;
      font-weight: 500;
    }
    
    .order-number-001{
  
      width: 100px;
    
    } 
  
    .btn-container-003{
  
      width: 70px;
    }
    .blue-container-data-flexing {
    display: flex;
  }
  }