.about-wrap {

    padding-top: 1rem;


}



.title-about {
    padding-top: 1rem;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.title-about h6 {

    font-family: "Rowdies", sans-serif;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #202020;
}

.title-about h4 {

    font-family: "Rowdies", sans-serif;
    font-size: 44px;
    font-weight: 700;
    line-height: 46.4px;
    text-align: center;
    padding-bottom: 0.8rem;
    color: #6423DB;

}

.care-about-single-card {

    width: 356px;
    height: 330px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border: 3px solid #4A27DF;
    border-radius: 12px;


}

.care-about-single-card-img-container {
    width: 220px;
    height: 224px;
    display: flex;
    padding-top: 1rem;

}

.care-about-single-card-img-container img {
    width: 220px;
    height: 220px;
    object-fit: fill;
    margin-left: 5.5rem;
}

.care-about-cards-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 7%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.care-about-single-card-header h4 {

    text-align: center;
    font: normal normal normal 20px/26px Link Sans;
    letter-spacing: 0.33px;
    color: #000000;

}

/* .title-about h5 {
    font-family: "Link Sans ExtraBold"; 
    font-size: 44px;
    font-weight: 400;
    line-height: 20.97px;
    text-align: left;
    color: rgba(107, 107, 107, 1);
    padding-bottom: 0.8rem ;

} */

.card-true2 {
    display: flex;
    flex-direction: row;
    column-gap: 2%;
    margin-bottom: 0.7rem;
    margin-top: 0.3rem;
}

.card-true2 img {
    width: 26px;
    height: 26px;
}

.card-true2 h6 {

    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
}


.padding-img {
    padding: 2rem;
}

.title-about button {

    width: 142px;
    height: 44px;
    gap: 0px;
    border-radius: 9px;
    border: 0px;
    opacity: 0px;
    color: white;
    background: rgba(74, 104, 217, 1);
    font-family: Poppins;
    font-size: 17px;
    font-weight: 500;
    line-height: 25.46px;
}

@media (max-width: 480px) {

    .care-about-cards-grid {

        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        justify-content: center;
        align-items: center;
    }
    

    .title-about h6 {

        font-family: Poppins;
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        color: #202020;
    }
    
    .title-about h4 {
    
        font-family: "Link Sans ExtraBold";
        font-size: 32px;
        font-weight: 700;
        line-height: 46.4px;
        text-align: center;
        padding-bottom: 0.8rem;
        color: #6423DB;
    
    }

    
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {


    .care-about-single-card {

        width: auto;
        height: auto;
        background: #F7F7F7 0% 0% no-repeat padding-box;
        border: 3px solid #4A27DF;
        border-radius: 12px;
    
    
    }

    .care-about-single-card-img-container img {
     
        margin-left: 1rem;
    }

    .care-about-single-card-header h4 {

        text-align: center;
        font: normal normal normal 20px/26px Link Sans;
        letter-spacing: 0.33px;
        color: #000000;
        font-size: 18px;
        padding: 0.5rem;
    
    }

    .care-about-cards-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 3%;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    
    
}