.upload-button-btn{

    width: 150px;
    height: 30px;
    border: 0px;
    border-radius: 8.72px;
    background: rgba(248, 248, 248, 1);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.17);
    font-family: Poppins;
    font-size: 14.5px;
    font-weight: 400;
    line-height: 23.26px;
    text-align: center;
    color: rgba(0, 0, 0, 1);


}

.modal-buttons-save-cancel{

    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10%;
    margin-top: 1rem;
}

.modal-buttons-save-cancel button {

    width: 202px;
    height: 42px;
    border-radius: 7.64px;
    background-color: white;
    border: 1px solid #4a68d9;
    font-family: Poppins;
    font-size: 16.4px;
    font-weight: 400;
    line-height: 24.6px;
    text-align: center;
    color: #4a68d9;
}

/* .savegreen{


    width: 202px;
    height: 42px;
    border-radius: 7.64px;
    background-color: white;
    border: 1px solid #32de84 ;
    font-family: Poppins;
    font-size: 16.4px;
    font-weight: 500;
    line-height: 24.6px;
    text-align: center;
    color: white;
    background-color: #4FFFB0;

} */

.uploading-message {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .uploading-message .spinner {
    margin-bottom: 10px;
    /* Add styles for your spinner here */
  }
  
  .uploading-message p {
    font-size: 16px;
    color: #333;
  }
  
/* In FalconImage.css */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  