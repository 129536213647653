.upload-images-container .upload-img-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: row;
    column-gap: 15%;
  }
  
  .upload-img-1 span {
    font-family: Poppins;
    font-size: 16.25px;
    font-weight: 400;
    line-height: 25.88px;
    color: #000000;
    padding-top: 0.3rem;
  }
  
  .Basic-card-Data h6 {
    font-family: Poppins;
    font-size: 22.55px;
    font-weight: 600;
    line-height: 33.82px;
    text-align: center;
    color: #000000;
    padding-top: 1rem;
  }
  
  .inputs-flexing-inline {
    display: flex;
    flex-direction: row;
    column-gap: 2%;
  }
  
  .single-Input-holder label {
    font-family: Poppins;
    font-size: 16.25px;
    font-weight: 400;
    line-height: 25.88px;
    text-align: left;
  }
  
  .single-Input-holder .dropdown-btn-001 {
    background-color: transparent;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    color: #000000;
    font-weight: 400;
    border: 0px;
  }
  
  
  .labels-container{
  
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    
  }
  .inputs-header {
    display: grid;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  
  
  .Add-new-card-btn {
    width: 187px;
    height: 40.07px;
    border-radius: 8px;
    box-shadow: -1.21px 0px 4.86px 0px #0000002b;
    border: 0px;
    background: #4a68d9;
    color: #ffffff;
    font-family: Poppins;
    font-size: 18.83px;
    font-weight: 400;
    line-height: 28.24px;
  }
  
  .button:focus:not(:focus-visible) {
  
    border: none;
  }
  
  .Remove-new-card-labels {
  
    background-color: #dc3545;
    color: white;
    font-weight: 700;
    border: none;
    padding: 0px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    height: 40px;
    width: 100px;
  }
  
  
  
  .padding-bottom-mob {
    width: 100px;
    height: 54px;
  }
  
  .table-form {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-form th, .table-form td {
    padding: 10px;
    text-align: left;
  }
  
  .table-form th {
    font-weight: bold;
  }
  
  .form-control {
    width: 100%;
  }
  
  .dropdown-menu{
    overflow-y: auto;
    max-height: 200px;
  
  }
  
  
  
  .dropdown-btn-001 {
    width: 100%;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
  }
  
  .table-container {
    overflow-x: none;
    -webkit-overflow-scrolling: touch; /* Adds smooth scrolling on iOS */
  }
  
  .table-form {
    min-width: 600px; /* Adjust as necessary based on your table's width */
  }
  
  
  @media (max-width: 480px) {
    .upload-images-container .upload-img-1 {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
  
    .inputs-flexing-inline {
      display: flex;
      flex-direction: column;
      row-gap: 2%;
    }
  
    .single-Input-holder .dropdown-btn-001 {
      background-color: transparent;
      border: var(--bs-border-width) solid var(--bs-border-color);
      color: #000000;
      font-weight: 400;
      width: 100%;
      text-align: left;
    }
  
    .table-container {
      overflow-x: none;
      overflow: scroll;
      -webkit-overflow-scrolling: touch; /* Adds smooth scrolling on iOS */
    }
  
    .dropdown-menu{
      overflow-y: auto;
      max-height: 200px;
      position: relative;
    }
    
    
    .Remove-new-card-labels {
  
      background-color: #dc3545;
      /* Red background */
      color: white;
      /* White text color */
      border: none;
      /* No border */
      padding: 8px 12px;
      /* Padding */
      border-radius: 10px;
      /* Rounded corners */
      cursor: pointer;
      /* Pointer cursor on hover */
      font-size: 14px;
      /* Font size */
      transition: background-color 0.3s;
      /* Smooth transition */
      height: 44px;
      margin-top: 0rem;
      margin-bottom: 0rem ;
      width: auto;
      height: 40.07px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
  
    .upload-img-1 span {
      font-family: Poppins;
      font-size: 14.25px;
      font-weight: 400;
      line-height: 25.88px;
      color: #000000;
      padding-top: 0.3rem;
      width: 200px;
    }
  
    .upload-images-container .upload-img-1 {
      display: flex;
      flex-direction: row;
      column-gap: 5%;
    }
  }