@import "~@fortawesome/fontawesome-free/css/all.min.css";
#root{

  display: flex;
  flex-direction: column;
  min-height: 100vh;

}

.signinform {
  box-shadow: 0px 1px 8.6px 2.73px rgba(114, 112, 112, 0.49);
  margin-top: 4rem;
  width: 820px;
  height: 100%;
  padding: 20px 105px 20px 105px;
  border-radius: 6.13px ;
  margin-bottom: 3rem;
  margin-top: 12rem ;
  padding-bottom: 4rem;
}

 .form-sign-in-1{
  margin-top: 6rem;
  border-radius: 9px ;
  background: rgba(255, 255, 255, 1);
  box-shadow: 1px 0px 9.3px 0px rgba(0, 0, 0, 0.25);
  padding: 3rem ;
  padding-top: 2rem;
  width: 504px;
  height: auto;

}

.form-title h6 {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
  color: #6423DB;
  padding-top: 2rem;
}

.form-label {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

/* .form-control {
  padding: 11.21px 8.1px 11.21px 13.7px;
  gap: 6.23px;
  border-radius: 5.29;
  border: 0.62px 0px 0px 0px;
  opacity: 0px;
  font-family: Poppins;
  font-size: 12.83px;
  font-weight: 400;
  line-height: 19.25px;
} */
.form-btns-sec {
  display: flex;
  flex-direction: column;
}

.signin-btn {
  height: 41px;
  border-radius: 5.38px;
  background-color:#6423DB;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border: 0px;
  color: white;
  margin-bottom: 1rem;
}

.signup-btn {
  height: 41px;
  gap: 5.98px;
  border-radius: 5.38px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #6423DB;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  background: rgba(255, 255, 255, 1);
  color:#6423DB ;
  width: 100%;
}

.form-forgetpass{

    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: right;
    

}

.form-forgetpass span{
    font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: -0.019em;
text-align: right;
color: rgba(229, 73, 73, 1);

}


.error-message{
  color: rgba(229, 73, 73, 1);
  margin-bottom: 0.5rem;

}


.password-container {
  position: relative;
}

.show-password-icon {
  position: absolute;
  top: 70%;
  right:3%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #007bff;
}

.eagle-cont2 .eagle2{

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;

} 

.center-tablet{

}

@media (max-width:480px) {

  .signinform {
    width: 90%; /* Adjust width to fit smaller screens */
    height: 100%;
    padding: 20px; /* Reduce padding for smaller screens */
    margin-left: auto;
    margin-right: auto;
    margin-top: 8rem;
    margin-bottom: 2rem;

  }

  .show-password-icon {
    position: absolute;
    right: 4%;
    top: 70%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 16px;
    color: #007bff;
  }


     .form-sign-in-1{
    margin-top: 1rem;
    border-radius: 9px ;
    background: rgba(255, 255, 255, 1);
    box-shadow: 1px 0px 9.3px 0px rgba(0, 0, 0, 0.25);
    padding: 2rem ;
    padding-top: 2rem;
    width: 100%;
    height: 100%;
  
  }

  .eagle-cont2 .eagle2{

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0rem;

}

}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
  

  .signinform {
    width: 90%;
    height: 100%;
    padding: 20px; /* Reduce padding for smaller screens */
    margin-left: auto;
    margin-right: auto;
    margin-top: 17rem;
    margin-bottom: 0rem;
    padding-bottom: 4rem;


  }


     .form-sign-in-1{
    margin-top: 1rem;
    border-radius: 9px ;
    background: rgba(255, 255, 255, 1);
    box-shadow: 1px 0px 9.3px 0px rgba(0, 0, 0, 0.25);
    padding: 2rem ;
    padding-top: 2rem;
    width: 100%;
    height: 100%;
  
  }

   .eagle-cont2 .eagle2{

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0rem;

}


}



