.Fill-data-container h2 {

    text-align: center;
    font-size: 36px;
    padding-bottom: 1rem;
    color: white;
    font-weight: 700;
    padding-top: 2rem;
    font-family: "Lateef", serif;

}

.input-Field-orders{

    margin-top: 0.8rem;
    margin-bottom: 0.3rem;
}


.page-container {

    background-color: black;
    min-height: 100vh;
    padding-bottom: 2rem;
    font-family: "Lateef", serif;


}

.Fill-data-container form label {

    color: white;
    font-family: 700;
    font-family: "Lateef", serif;
    font-size: 28px;
}


.Update-falcon-btn {
    background-color: #85754e;
    width: auto;
    border-radius: 10px;
    height: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 0px;
    font-size: 24px;
    font-family: "Lateef", serif;
    font-weight: bold;
    color: #fafafa;
    }

@media (max-width: 480px) {
    .Fill-data-container h2 {

        text-align: center;
        font-size: 36px;
        padding-top: 1rem;

    }


    .page-container {

        padding: 2rem;
        padding-bottom: 3rem;

    }


    .Fill-data-container form label {

        color: white;
        font-size: 26px;
    }

    .input-Field-orders{

            margin-top: 0.6rem;
            margin-bottom: 0.3rem;
    }


    .Update-falcon-btn {
    background-color: #85754e;
    width: auto;
    border-radius: 10px;
    height: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 0px;
    font-size: 24px;
    font-family: "Lateef", serif;
    font-weight: bold;
    color: #fafafa;
    }
}