.profile-wrapper-container {
  width: 1238px;
  height: 100%;
  border-radius: 6.13px 0px 0px 0px;
  background: #ffffff;
  box-shadow: 0px 1px 7.3px 2px #d9d9d9de;
  margin-top: 10rem;
  margin-bottom: 2rem;
  padding: 3rem;
}

.grid-layout-left-bar-content {
  display: flex;
  column-gap: 10%;
}

.left-menu-list {
  list-style-type: none;
}

.left-menu-item {
  font-family: Poppins;
  font-size: 19.38px;
  font-weight: 400;
  line-height: 29.07px;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  padding: 0.5rem;
}

.left-menu-item.active {
  background-color: #8da0e570;
  /* Active background color */
  color: #4a68d9;
  border-radius: 8px;
  padding: 0.5rem;
}

/* General layout styles */
.profile-layout-container {
  position: relative;
}

/* Button styles */
.off-canvas-menu-button {
  display: none; /* Hide by default on larger screens */
  position: absolute;
  top: 10.9%;
  left: 5%;
  font-size: 24px;
  background: transparent;
  color: #000000;
  border: none;
  cursor: pointer;
  z-index: 1001; /* Make sure the button is above other content */
}

/* Hide button when menu is open */
.off-canvas-menu-button.hide {
  opacity: 0;
  pointer-events: none; /* Disable pointer events so it can’t be clicked */
}

/* Off-canvas menu styles */
.off-canvas-menu {
  position: fixed;
  top: 0;
  left: -250px; /* Hide the menu off-screen */
  width: 250px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 7.3px 2px #d9d9d9de;
  transition: left 0.3s ease;
  z-index: 1000; /* Make sure the menu is above other content */
}

.off-canvas-menu.open {
  left: 0; /* Slide the menu into view */
}

.off-canvas-menu .left-menu-list {
  padding: 1rem;
}

.off-canvas-menu .left-menu-item {
  font-size: 18px;
  padding: 1rem;
}

/* Default layout styles (Desktop) */
.profile-wrapper-container {
  width: 1238px;
  height: 100%;
  border-radius: 6.13px 0px 0px 0px;
  background: #ffffff;
  box-shadow: 0px 1px 7.3px 2px #d9d9d9de;
  margin-top: 10rem;
  margin-bottom: 2rem;
  padding: 3rem;
}

.grid-layout-left-bar-content {
  display: flex;
  column-gap: 10%;
}

.left-menu-bar {
  display: block; /* Show the default menu bar */
}

.left-menu-list {
  list-style-type: none;
}

.left-menu-item {
  font-family: Poppins;
  font-size: 19.38px;
  font-weight: 400;
  line-height: 29.07px;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  padding: 0.5rem;
}

.left-menu-item.active {
  background-color: #8da0e570;
  color: #4a68d9;
  border-radius: 8px;
  padding: 0.5rem;
}

.off-canvas-menu-button {

  border: 1px solid  #E8E8E8				;
  border-radius: 2px;
  background-color:transparent ;
  padding: 0.1rem;
  z-index: 1;
  font-size: 18px;
  position: absolute; /* Use absolute positioning */
  top: 7.5rem; /* Adjust as needed */
  left: 1rem; /* Adjust as needed */
}

.disabled {
  color: grey; /* Color for disabled items */
  pointer-events: none; /* Prevent click events */
  opacity: 0.6; /* Slightly faded appearance */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
}


/* Media query for tablet and mobile views */
@media (max-width: 768px) {
  .left-menu-bar {
    display: none; /* Hide the default left menu bar on small screens */
  }

  .off-canvas-menu-button {
    display: block; /* Show the button on small screens */
  }
  .profile-wrapper-container {
    width: 95% !important;
    height: 100%;
    border-radius: 6.13px 0px 0px 0px;
    background: #ffffff;
    box-shadow: 0px 1px 7.3px 2px #d9d9d9de;
    margin-top: 10rem;
    margin-bottom: 2rem;
    padding: 1rem;
  }

  .grid-layout-left-bar-content {
    display: block;
    column-gap: 0%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {


  .grid-layout-left-bar-content {
    display: flex;
    column-gap: 5%;
  }

}


