.verify-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 7rem;
}

.verify-btn {
  height: 41px;
  gap: 5.98px;
  border-radius: 5.38px;
  background: rgba(74, 104, 217, 1);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border: 0px;
  color: white !important;
  margin-top: 2rem;
}

.padding-center-verify {
  padding-top: 7%;
  box-shadow: 0px 1px 8.6px 2.73px rgba(114, 112, 112, 0.49);
  margin-top: 6rem;
  width: 820px;
  height: 477px;
  padding: 20px 105px 20px 105px;
  border-radius: 6.13px 0px 0px 0px;
  margin-bottom: 4rem;
  margin-top: 12rem;
}


.padding-center-verify h6 {

        font-family: Poppins;
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        text-align: center;
        color: rgba(74, 104, 217, 1);
        padding-top: 2rem;
      

}

@media (max-width: 480px) {

  .padding-center-verify {
    padding-top: 7%;
    box-shadow: 0px 1px 8.6px 2.73px rgba(114, 112, 112, 0.49);
    margin-top: 6rem;
    width: 95%;
    height: 100%;
    padding: 20px 105px 20px 105px;
    border-radius: 6.13px 0px 0px 0px;
    margin-bottom: 2rem;
    margin-top: 8rem;
  }
 
  

  .verify-img-container {
    display: flex;
    justify-content: safe;
    padding-top: 1rem;
  }
  .padding-center-verify h6 {

    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
    color: rgba(74, 104, 217, 1);
    padding-top: 2rem;
  

}
  
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}