#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.falcon-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  flex-direction: column;
}

.download-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end; 
  margin-top: 10rem;

}

.pdf-download-btn {

  width: 170px;
  height: 44px;
  border-radius: 9px;
  border: 0px;
  background: #4A68D9;
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

}

.Wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;

  background: linear-gradient(180deg,
      #4a68da 0%,
      #7369ca 20.5%,
      #816ac5 39%,
      #906ac0 82.5%,
      #a36ab9 96.5%);
  column-gap: 5%;
  box-shadow: -1px 0px 6.2px 0px #74747440;
  width: 50%;
}

.img-fluid-falcon-left {
  width: 100%;
  height: 100%;
  object-fit: fill;

}

.left-card-slice {
  width: 100%;
  height: 700px;

}

.grid-layout-falcon {
  width: 100%;
  height: 700px;
  display: flex;
}

.img-details-1 {
  width: 65px;
  height: 40px;
  margin-top: 2.3rem;
}

.data-of-falcon-5 {
  margin-top: 2.3rem;
  margin-left: 1rem;
  font-size: 14px;
  color: white;
  font-family: Poppins;
  font-weight: 400;
  text-align: left;
}

.data-of-falcon-5 h6 {
  margin-bottom: 2px;
  font-weight: 600;
}

.data-of-falcon-5 p {
  font-size: 14px;
}

.line {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 670px;
}

.left-right-detail {
  display: flex;
  flex-direction: row;
}

.flex-wrap-right {
  display: flex;
  flex-direction: column;
}

@media (max-width: 480px) {
  .falcon-card {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
  }

  .Wrapper {
    display: flex;
    padding: 2rem;
    flex-direction: column;

    width: 100%;
  }

  .img-fluid-falcon-left {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    /* object-fit: cover; */
  }

  .left-card-slice {
    width: 100%;
    height: 100%;
  }


  .text-profile-img {


    width: 100%;
    height: 100%;
  }


}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .falcon-card {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 9rem !important;
  }

  .edit-mode {
    margin-left: 0rem;
  }
}