.Partner-Sec {
  padding: 3rem;
  padding-bottom: 2rem;
  position: relative;

}

.white-background-color {

  background-color: #fafafa;

}

.Partner-titles h6 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}

.Partner-titles h5 {
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  line-height: 54px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}

.Partner-titles h4 {
  font-family: "Rowdies", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  color: #4A27E3;
  text-transform: capitalize;
  font-size: 44px;
  font-weight: bold;

}


.Partner-titles p {
  font-family: "Rowdies", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #6B6B6B;
  font-size: 15px;

}

.swiper-slide-auto {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}

.image-container {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  max-width: 100%;
  height: auto;
}


.partner-item-container img {


  width: 156px;
  height: 78px;
}

.partner-item-container2 {

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.7rem;
}

.flex-partner-layout-list {


  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 5%;
}

@media (max-width: 480px) {
  .image-container {
    width: 100%;
    height: 100%;
  }

  .Partner-titles h5 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 54px;
    text-align: center;
    color: rgba(0, 0, 0, 1);
  }

  .image-container img {
    width: 130px;
    height: 50px;
  }

  .flex-partner-layout-list {

    display: grid;
    grid-template-columns: 1FR 1FR;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;


  }

}

/* .Partner-Sec::before,
.Partner-Sec::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 1;
}

.Partner-Sec::before {
  top:40%;
  left: calc(100vw - 100%); /* Position it at the right edge of the screen */
  /* transform: translateX(500%); /* Move it to the left side */
  /* background-image: url('/webapps/tippytap/src/Assets/Group 231@2x.png');
  width: 168px;
  height: 212px; */
/* } */ 

/* .Partner-Sec::after {
  bottom: -1%;
  right: 100%;
  background-image: url('/webapps/tippytap/src/Assets/Group 229@2x.png');
  width: 176px;
  height: 141px;
} */ 


@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .flex-partner-layout-list {

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    column-gap: 15%;


  }


  .partner-item-container {
    width: 156px;
    height: auto;
  }

}