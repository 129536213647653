.Modal2 {
    background: white;
    padding: 20px;
    border-radius: 4px;
    width: 633px;
    height: auto;
    top: 636px;
    max-width: 500px;
    margin: auto; /* Center horizontally */
    position: relative;
    z-index: 1000;
    top: 50%;
    left: 14%;
    transform: translate(-50%, -50%); /* Center the modal */
    border-radius: 10px;
    padding: 2rem;
  }
  .Overlay2 {
    background: rgba(0, 0, 0, 0.5);
    position: fixed; /* Ensure overlay covers the entire screen */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999; /* Must be less than the modal's z-index */
  }
  .model-btns-sec2{

    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10%;
    
    }
    

    .width-form-control input {


      width: 105%;
    }
    
    .add-falcon{
        width:195px;
        height: 42px;
        border-radius: 7.64px ;
        background-color: white;
        border: 1px solid #4A68D9;
        font-family: Poppins;
        font-size: 16.4px;
        font-weight: 400;
        line-height: 24.6px;
        text-align: center;
        color: #4A68D9;
    
    
    }

    @media (max-width: 480px) {


         
    .add-falcon{
      width:100%;
      height: 42px;
      border-radius: 7.64px ;
      background-color: white;
      border: 1px solid #4A68D9;
      font-family: Poppins;
      font-size: 16.4px;
      font-weight: 400;
      line-height: 24.6px;
      text-align: center;
      color: #4A68D9;
  
  
  }

  
    }