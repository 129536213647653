.Pricing-wrap {
  background-color: #fafafa;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.pricing-titles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 0rem;
  padding-bottom: 3rem;
}

.pricing-titles2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 3rem;
}




.pricing-titles2 h6 {
  font-family: "Rowdies", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 35.94px;
  text-align: center;
  color: #000000;
}

.pricing-titles2 p {
  font-family: "Rowdies", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.97px;
  text-align: center;
  color: #6b6b6b;
  width: 644px;
}

.pricing-titles h6 {
  font-family: "Rowdies", sans-serif;
  font-size: 44px;
  font-weight: 700;
  line-height: 59.9px;
  text-align: center;
  color: #4925E1;
}

.pricing-titles span {
  font-family: "Rowdies", sans-serif;
  font-size: 44px;
  font-weight: 700;
  line-height: 59.9px;
  text-align: center;
  color: #000000;
}

.pricing-titles p {
  font-family: "Rowdies", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 26.96px;
  text-align: center;
  color: #5a5a5a;
  padding: 0px;
}

.pricing-cards-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 5.5%;
}

.card20 {
  width: 385.29px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 1.75px 9.61px 0px #d1d0d6;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.header-card-flex {
  display: flex;
  flex-direction: row;
  column-gap: 26%;
}

.header-titles h6 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 38px;
  color: #000000;
  margin-bottom: 0px;
  text-align: left;
}

.header-titles span {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}

.price-tag-float-right {
  position: relative;
  display: inline-block;
}

.pricetag-img {
  margin-top: -1.8rem;
  display: block;
  /* Remove any extra space below the image */
}

.price-tag-text {
  position: absolute;
  top: -5%;
  /* Move the text 14px above the image */
  left: 58%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Adjust for horizontal centering */
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  line-height: 45px;
  text-align: left;
  color: #6423DB;
}

.styled-line {
  border: 2px dashed #c8c8c8;
  margin-top: 1rem;
  /* Adjust the spacing around the line */
  height: 3px;
}


.card-body20 {
  display: flex;
  flex-direction: column;
}

.counter-container,
.order-now-btn-container {
  display: flex;
  justify-content: center;
  margin-top: auto;
  /* Push the buttons to the bottom of the card */

}

.card-features {
  display: flex;
  margin-bottom: 1rem;

}



.card-features-text {
  margin-left: 0.6rem;

  font-family: Poppins;
  font-size: 12.23px;
  font-weight: 500;
  line-height: 18.32px;
  text-align: left;
  color: #000000;
}

.order-now-btn1 {
  width: 338.5px;
  height: 52px;
  border-radius: 26px;
  border: 1px solid #6423DB !important;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  line-height: 25.46px;
  color: #6423DB;
  background-color: white;
}

.order-now-btn1:hover {
  background-color: #6423DB; /* Change to desired hover background color */
  color: #ffffff; /* Optional: change text color on hover */
}

.order-now-btn-container .order-now-btn-BLUE {
  width: 338.5px;
  height: 52px;
  border-radius: 26px;
  border: 0px;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  line-height: 25.46px;
  color: white;
  background-color: #6423DB;
}

.order-now-btn-container .order-now-btn-BLUE:hover {
  background-color: white; /* Change to desired hover background color */
  color: #6423DB; /* Optional: change text color on hover */
  border: 1px solid #6423DB !important;
}

.card20:hover{

  border: 1px solid #6423DB !important;
  border-radius: 8px;
}

.order-now-btn-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.counter-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.decrement-btn {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  color: white;
  background-color: #4A27DF;
  border: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.46px;
  padding: 9px;
}

.INcrement-btn {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  color: white;
  background-color: #4A27DF;
  border: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.46px;
  padding: 9px;
}

.counter-value {
  width: 73px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #4A27DF;
  font-family: Poppins;
  font-size: 20px;
  padding-top: 0.3rem;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  /* Ensure padding and border are included in width */
  display: inline-block;
}

.card-features img {
  width: 17.47px;
  height: 15px;
  gap: 0px;
  margin-top: 0.1rem;
}

.Buy-now-btn {
  width: 328.5px;
  height: 52px;
  border-radius: 26px;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  line-height: 25.46px;
  color: white;
  background-color: #6423DB;
  border: 0px;
}

.buy-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.flex-pricing p{

  color: #000000;
  font-family:"Link Sans ExtraBold";
  font-size: 16px;
  padding-top: 0.5rem;

}

@media (max-width: 480px) {
  .pricing-cards-grid {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .card20 {
    justify-content: center;
    width: auto;
  }

  .pricing-titles {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 0rem;
    padding-bottom: 1rem;
  }

  .pricing-titles p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    line-height: 26.96px;
    color: #5a5a5a;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .pricing-titles2 h6 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    line-height: 35.94px;
    text-align: center;
    color: #000000;
  }

  .pricing-titles2 p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 20.97px;
    text-align: center;
    color: #6b6b6b;
    width: 100%;
  }

  .card-features-text {
    margin-left: 0.6rem;
  
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.32px;
    text-align: left;
    color: #000000;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .pricing-cards-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    column-gap: 4%;
    row-gap: 1rem; /* Add some space between rows */
  }
  
  .pricing-cards-grid > *:nth-child(3) {
    grid-column: 1 / 3; /* Make the third item span across two columns */
    justify-self: center; /* Center the third item */
  }
  .card20 {
    width: 340px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 1.75px 9.61px 0px #d1d0d6;
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
  }


}
