.navbar-wrapper {
  background-color: rgba(248, 248, 248, 1);
  position: fixed;
  top: 50px; /* Adjust this value to be greater than the height of `Bluenav` */
  left: 0;
  width: 100%;
  z-index: 900;
}

.tippytap-logo{
  width: 55px;
  height:50px;
}

nav {
  height: 80px;
  color: #f8f8f8;
}

.navbar-nav {
  margin-left: 17.5rem;
}

.navbar > .container-fluid {
  align-items: center;
}

.navbar-nav .nav-item {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-right: 2rem;
}
.nav-item a {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: black;
}

.navbar-toggler {
  position: relative;
  z-index: 1050;
}
.navbar-collapse {
  z-index: 1040; /* Adjust as needed */
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.nav-item .singup-nav-btn {
  color: white;
  background-color: #6423DB;
  border: 0px;


}

.dropdown-item {
  text-align: center;
}

.profile-section {
  z-index: 1000; /* Ensure it’s on top of other elements */
}

.profile-section {
  margin-left: auto; /* Pushes the profile section to the right */
}
.hide-profile {
  display: none; /* Hide when menu is toggled */
}

.sign-up-btn {
  display: block; /* Ensure the button is displayed */
}

.scroll-link{

  cursor: pointer;
}

@media (max-width: 480px) {
  .navbar-nav {
    margin-left: 0rem;
  }

  nav {
    height: auto;
    color: #f8f8f8;
  }

  .navbar-toggler {
    margin-left: auto;
  }

  .profile-section {
    margin-left: 5%;
  }
  .navbar-wrapper {
    top: 30px; /* Adjust this value to be greater than the height of `Bluenav` */
  }
  .navbar-toggler {
    position: relative;
    z-index: 1050;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .navbar-nav {
    margin-left: 1rem;
  }

  .navbar-toggler {
    margin-left: auto;
  }

  .profile-section {
    margin-left: 5%;
  }

  nav {
    height: auto;
  }


}


@media (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles specific to iPad Pro 12.9-inch */
  .navbar-nav {
    margin-left: 7rem;
  }

  .navbar-toggler {
    margin-left: auto;
  }

  .profile-section {
    margin-left: 5%;
  }
}



