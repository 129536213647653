.purple-nav-section {
    background: linear-gradient(#aa67c4, #6423DB);
    padding-top: 1rem;
    height: 310px;
  }
  
  .sec-nav {
    width: 140px;
    height: 48px;
  }
  
  .qr-card-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .qrcard {
    width: 646px;
    height: 290px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #00000015;
    border-radius: 6px;
  }
  
  .qr-card-body {
    display: flex;
    flex-direction: row;
    column-gap: 10%;
  }
  
  .qr-person-img {
    width: 240px;
    height: 290px;
  }
  
  .qr-card-titles h6 {
    font: normal normal 600 40px/46px Poppins;
    letter-spacing: 0px;
    color: #000000;
    font-size: 40px;
  }
  
  .qr-card-titles {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
  }
  
  .qr-card-titles span {
    font: normal normal 300 24px Poppins;
    letter-spacing: 0px;
    color: #696969;
    font-size: 24px;
  }
  
  .contacts-imgs {
    display: flex;
    flex-direction: row;
    column-gap: 5%;
    padding-top: 1rem;
  }
  
  .contact-img-1-container {
    width: 30px;
    height: 30px;
    /* UI Properties */
    background: #aa67c4 0% 0% no-repeat padding-box;
    border-radius: 23px;
  }
  
  .contact-img-1 {
    width: 30px;
    height: 30px;
    padding: 7px;
  }
  
  .addtocontact-btn {
    width: 646px;
    height: 48px;
    background: #6423DB 0% 0% no-repeat padding-box;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    border: 0px;
    margin-top: 0.7rem;
  }
  
  .profile-bio {
    padding-top: 1rem;
    border-bottom: 1px solid #c4c4c4;
  }
  
  .profile-bio h6 {
    font: normal normal medium 24px/28px Poppins;
    letter-spacing: 0px;
    color: #000000;
    font-size: 24px;
  }
  
  .profile-bio p {
    font-size: 18px;
    font: normal normal 300 18px/24px Poppins;
    letter-spacing: 0px;
    color: #696969;
  }
  
  .grid-social-network-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20%;
  }
  
  .social-network-img-container-right img {
    width: 370px;
    height: 291px;
    margin-left: 15%;
  }
  .social-network-img-container-right {
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .social-network-container-right h6 {
    font: normal normal medium 24px/28px Poppins;
    letter-spacing: 0px;
    color: #000000;
    font-size: 24px;
    padding-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .List-of-icons {
    display: flex;
    flex-direction: row;
    column-gap: 2%;
    padding-top: 0.5rem;
  }
  
  .social-icon-img-000 {
    width: 46px;
    height: 46px;
  }
  
  .grid-personal-social-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20%;
    padding-top: 2rem;
    row-gap: 10%;
  }
  .single-card-phone-data {
    border-bottom: 1px solid #c4c4c4;
  }
  
  .single-card-phone-titles h6 {
    font: normal normal medium 18px/28px Poppins;
    letter-spacing: 0px;
    color: #000000;
  }
  
  .single-card-phone-titles p {
    font: normal normal 300 18px/28px Poppins;
    letter-spacing: 0px;
    color: #696969;
  }
  
  .single-card-phone-data {
    display: flex;
    column-gap: 10%;
  }
  
  .border-center {
    border-bottom: 1px solid #c4c4c4;
  }
  .attachments-section {
    margin-top: 4rem;
    padding-bottom: 2rem;
    width: 100%;
    overflow-x: auto;
  }
  
  .attachments-section h6 {
    font: normal normal medium 24px/28px Poppins;
    letter-spacing: 0px;
    color: #000000;
    font-size: 24px;
    padding-bottom: 1rem;
  }
  
  .List-of-attachment {
    display: flex;
    column-gap: 4%;
    
  }
  
  .single-attachment-container {
    width: 169px;
    height: auto;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    row-gap: 1%;
    padding:1rem
  }
  .single-attachment-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  


  .attachment-label{

    font-size:16px;
    color:#000000;
    text-decoration: none !important;
    text-decoration-line: none !important;
  }

  .attachment-img{

    width:100%
  }
  @media (max-width: 480px) {
    .purple-nav-section {
      background: linear-gradient(#aa67c4, #6423DB);
      padding-top: 1rem;
      height: 153px;
    }

    .attachment-img{

      width:auto;
    }
    .qrcard {
      width: 268px;
      height: 120px;
      margin-top: 2rem;
    }
  
    .qr-person-img {
      width: 100px;
      height: 120px;
    }
  
    .qr-card-titles {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      padding-top: 0rem;
    }
    .qr-card-titles h6 {
      font-size: 16px;
      margin: 0px;
      height: 30px;
    }
    .qr-card-titles span {
      font-size: 12px;
    }
    .contacts-imgs {
      padding-top: 0rem;
    }
    .qr-card-body {
      column-gap: 3%;
    }
  
    .addtocontact-btn {
      width: 268px;
    }
    .profile-bio {
    }
    .grid-social-network-section {
      display: flex;
      flex-direction: column;
      row-gap: 3rem;
    }
  
    .grid-personal-social-data {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      padding-top: 2rem;
    }
  
    .phone-icon-1 {
      width: 15px;
      height: 24px;
    }
  
    .single-card-phone-data {
      display: flex;
      column-gap: 5%;
    }
  
    .List-of-icons {
      display: flex;
      flex-direction: row;
      column-gap: 8%;
      padding-top: 0.5rem;
    }
  
    .single-attachment-container {
      width: 169px !important;
      height: 169px;
    }
  
    .attachment-img {
      width: 72px;
      height: 72px;
    }
  
    .social-network-img-container-right img {
      width: 218px;
      height: 171px;
      margin-left: 15%;
    }
  
    .List-of-attachment{
  
      width: 100%;
      column-gap: 1%;
      overflow-x: auto !important; /* Enable horizontal scrolling */
      white-space: nowrap; /* Prevent wrapping of child elements */
      display: flex;
      
    }
  
  
  
    .single-attachment-container{
  
      margin-left: 0px;
      
    }
  
  
  }
  