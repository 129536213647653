.qr-modal {
    background: white;
    padding: 2rem; /* Adjust as needed */
    border-radius: 10px;
    width: 100%;
    max-width: 500px; /* Adjust width as needed */
    height: auto;
    margin: auto; /* Center horizontally */
    position: fixed;
    z-index: 1049;
    top: 50vh; /* Use viewport height for better centering */
    left: 50%;
    transform: translate(-50%, -50%); /* Better centering of the modal */
    border: 0 !important;
}

.qr-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed; /* Ensure overlay covers the entire screen */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050; /* Must be greater than the modal's z-index */
    border: 0px !important;
}

.front-image-preview-container {
    position: relative;
    display: flex;
    max-width: 100%;
    max-height: 100%; /* Ensure the container doesn't exceed the modal size */
    overflow: hidden;
}

.front-image-preview {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the entire image is visible within the container */
}

.qr-code-overlay {
    position: absolute;
    cursor: move;
}

.qr-code-icon {
    width: 150px;
    height: 150px;
}

.close-btn2 {
    position: absolute;
    right: 20px;
    top: 15px;
    background: none;
    border: none;
    font-size: 25px;
    font-weight: 700;
    color: black;
}

.cancel-modal-QR-btn {
    width: 100%;
    height: 42px;
    border-radius: 7.64px;
    background-color: white;
    border: 1px solid #6423DB;
    font-family: Poppins;
    font-size: 16.4px;
    font-weight: 400;
    line-height: 24.6px;
    text-align: center;
    color: #6423DB;
}
