.pravicy-wrapper {
  margin-top: 10rem;
}

.header-privacy {
  font-size: 32px;
  color: #2e3758;
  font-family: Poppins;
  font-weight: 600;
}



.text-privacy {
    font-size: 16px;
    color: #212529;
    font-family: Poppins;
    font-weight: 400;
  }


  .small-content{

    margin-top: 5rem !important;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5) !important;
  }


  .Section-6 {
    margin-bottom: 2rem;
  }
