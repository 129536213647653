#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.flex-register-main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 1rem;
  column-gap: 10%;
  margin-top: 8rem;
}

.form-registration-main {
  margin-top: 2rem;
  width: 544px;
  border-radius: 9px;
  /* background: red; */
  /* box-shadow: 1px 0px 9.3px 0px rgba(0, 0, 0, 0.25); */
  padding: 3rem;
  padding-top: 1rem;
  box-shadow: 1px 0px 9.3px 0px #00000040;
}

.register-btn-container {
  display: flex;
  flex-direction: column;
}

.register-btn-container button {
  gap: 5.09px;
  border-radius: 5px;
  height: 37.15px;
  background-color: #6423DB;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: white;
  border: 0px;
}

.register-btn-container small {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
  margin-top: 1rem;
}

.register-btn-container small span {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: rgba(219, 76, 76, 1);
}

.form-title3 h6 {
  font-family: Poppins;
  font-size: 22.19px;
  font-weight: 600;
  line-height: 30.29px;
  text-align: center;
  color: #6423DB;
  padding-top: 0px !important;
}

.eagle-cont4 .eagle3 {
  margin-top: 5rem;
}

.Purple-card-img{

  width: 100%;
  margin-top: 5rem;

}

@media (max-width: 480px) {
  .flex-register-main {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
  }

  .form-registration-main {
    margin-top: 2rem;
    width: 100%;
    left: 100%;
    gap: 0px;
    border-radius: 9px ;
    background: rgba(255, 255, 255, 1);
    box-shadow: 1px 0px 9.3px 0px rgba(0, 0, 0, 0.25);
    padding: 2rem ;
    padding-top: 1rem;
  }

  .abudhabi img {
    width: 100%;
  }
  .eagle-cont4 .eagle3 {
    margin-top: 1rem;
    width: 100%;
  }

  .left-register-img-sec{

    padding-top: 0rem;
  }


.Purple-card-img{

  width: 100%;
  margin-top: 0rem;

}

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .flex-register-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 2rem;
  }

  .form-registration-main {
    margin-top: 2rem;
    width: 100%;
    border-radius: 9px;
    padding: 3rem;
    padding-top: 1rem;
    box-shadow: 1px 0px 9.3px 0px #00000040;
  }

  .eagle-cont4 .eagle3 {
    margin-top: 1rem;
    width: 100%;
  }
}
