.profile-content h6 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #000000;
    margin-bottom: 1rem;
  }
  
  .editable-profile-container {
    width: 820px;
    height: 100%;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 0px 2.91px 0.48px #d9d9d9de;
    padding: 3rem;
  }
  
  .editable-personal-container h6 {
    padding-top: 4rem;
    font-family: Poppins;
    font-size: 23.26px;
    font-weight: 400;
    line-height: 34.89px;
    color: #000000;
    margin-bottom: 2rem;
  }
  
  .editable-address-container h6 {
    padding-top: 1rem;
    font-family: Poppins;
    font-size: 23.26px;
    font-weight: 400;
    line-height: 34.89px;
    color: #000000;
    margin-bottom: 2rem;
  }
  
  .Rows-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5%;
  }
  
  .img-and-name {
    display: flex;
    flex-direction: row;
    column-gap: 10%;
  }
  
  .img-and-name .img img {
    width: 92.06px;
    height: 92.06px;
    border-radius: 50%;
  }
  
  .img-and-name .name {
    display: flex;
    flex-direction: column;
  }
  
  .img-and-name .name h6 {
    font-family: Poppins;
    font-size: 23.26px;
    font-weight: 400;
    line-height: 34.89px;
    color: #000000;
    margin: 0px
  }
  
  .img-and-name .name span {
    font-family: Poppins;
    font-size: 15.5px;
    font-weight: 400;
    line-height: 23.26px;
    color: #00000075;
  }
  
  .edit-btn-2 {
    width: 82.37px;
    height: 28.1px;
    border-radius: 8px;
    border: 0px;
    background: #f8f8f8;
    box-shadow: 0px 0px 4px 0px #0000002b;
  }
  
  .camera-img {
    width: 100px;
    height: 100px;
  }
  
  .save-btn {
    background-color: #8da0e570 !important;
    /* Change to your desired color */
    color: #4a68d9 !important;
    /* Text color */
    font-weight: 600;
    border: 0px solid darkgreen;
    /* Example border */
    /* Add any other styles you want for the 'Save' state */
  }
  
  .btns-view-save {
    display: flex;
    column-gap: 5%;
  }
  
  .view-btn-card {
    width: 82.37px;
    height: 28.1px;
    border-radius: 8px;
    border: 0px;
    background: #f8f8f8;
    box-shadow: 0px 0px 4px 0px #0000002b;
    margin-top: 0.59rem;
  
  }
  
  .single-personal-data-layout span,
  p {
    font-family: Poppins;
    font-size: 15.5px;
    font-weight: 400;
    line-height: 23.26px;
    color: #00000075;
  }
  
  .single-address-data-layout span,
  p {
    font-family: Poppins;
    font-size: 15.5px;
    font-weight: 400;
    line-height: 23.26px;
    color: #00000075;
  }
  
  @media (max-width: 480px) {
    .editable-profile-container {
      width: 100%;
      height: 100%;
      padding: 0.5rem;
    }
  
    .editable-personal-container {
      padding-top: 0rem;
    }
  
    .img-and-name {
      display: flex;
      flex-direction: column;
      column-gap: 7%;
      width: 100%;
    }
  
    .Rows-data {
      display: flex;
      flex-direction: column;
    }
  
    .editable-personal-container h6 {
      padding-top: 1rem;
      margin-bottom: 1rem;
    }
  
    .edit-btn-2 {
      width: 82.37px;
      height: 28.1px;
      border-radius: 8px;
      border: 0px;
      background: #f8f8f8;
      box-shadow: 0px 0px 4px 0px #0000002b;
    }
  
    .img-and-name .img {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
    }
  
    .btns-view-save {
      display: flex;
      flex-direction: column;
      column-gap: 5%;
    }
  
    .name-myprofile {
      margin-top: 0.6rem;
    }
  
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
  
    .editable-profile-container {
      width: 100%;
      height: 100%;
      padding: 1rem;
    }
  
  }