#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flex-register {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 1rem;
  column-gap: 10%;
  padding-top: 8rem;
}
/* .form-title3 h6 {
  font-family: Poppins;
  font-size: 22.19px;
  font-weight: 600;
  line-height: 30.29px;
  text-align: center;
  color: rgba(74, 104, 217, 1);
  padding-top: 0px !important;
} */
.left-register-img-sec {
  padding-top: 2rem;
}
/* .left-register-img-sec p {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  padding-top: 1rem;
} */

.left-register-img-sec h1 {
  font-family: Poppins;
  font-size: 38px;
  font-weight: 400;
  line-height: 22.5px;
  color: rgba(74, 104, 217, 1);
  padding-top: 1rem;
  text-align: center;
}

.left-register-img-sec h2 {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 400;
  line-height: 22.5px;
  color: rgba(74, 104, 217, 1);
  margin-top: 2rem;
  text-align: center;
}

.left-register-img-sec h3 {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 400;
  line-height: 22.5px;
  color: rgba(74, 104, 217, 1);
  margin-top: 2rem;
  text-align: center;
}

.left-register-img-sec p strong {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}


.form-title10 h6 {
  font-family: Poppins;
  font-size: 34px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
  color: rgba(74, 104, 217, 1);
}

.form-title10 h5{

  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  color: rgba(74, 104, 217, 1);

}

/* .form-control001 {
  padding: 11.21px 8.1px 11.21px 13.7px !important;
  gap: 6.23px !important;
  border-radius: 5.29 !important;
  border: 0.62px 0px 0px 0px !important;
  opacity: 0px !important;
  font-family: Poppins !important;
  font-size: 12.83px !important;
  font-weight: 400 !important;
  line-height: 19.25px !important;
  height: 50px;
} */

.react-tel-input .form-control {
  height: 39px !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;

}
.react-tel-input .selected-flag {
  width: 45px !important;
}

.input-class {
  margin-bottom: 1rem !important;
}

/* .form-registration2 {
  margin-top: 2rem;
  width: 544px;
  left: 795px;
  gap: 0px;
  border-radius: 9px ;
  opacity: 0px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 1px 0px 9.3px 0px rgba(0, 0, 0, 0.25);
  padding: 3rem ;
  padding-top: 1rem;
} */

/* .form-sign-in-1{
  margin-top: 6rem;
  border-radius: 9px ;
  opacity: 0px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 1px 0px 9.3px 0px rgba(0, 0, 0, 0.25);
  padding: 3rem ;
  padding-top: 2rem;
  width: 504px;
  height: 400px;

} */


.form-registration-ADIHEX{
  margin-top: 2rem;
  width: 544px;
  border-radius: 9px ;
  /* background: red; */
  /* box-shadow: 1px 0px 9.3px 0px rgba(0, 0, 0, 0.25); */
  padding: 3rem ;
  padding-top: 1rem;
  box-shadow: 1px 0px 9.3px 0px #00000040;

}
.register-btn-container {
  display: flex;
  flex-direction: column;
}

.register-btn-container button {
  gap: 5.09px;
  border-radius: 5px;
  height: 37.15px;
  background-color: rgba(74, 104, 217, 1);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: white;
  border: 0px;
}

.register-btn-container small {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
  margin-top: 1rem;
}

.register-btn-container small span {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: rgba(219, 76, 76, 1);
}

/* .eagle-cont {
  padding-top: 1.5rem;
}


.eagle-cont2 {
  padding-top: 1.5rem;

}


.eagle-cont4 .eagle3 {

  margin-top: 4rem;


}
.eagle-cont2 .eagle2{

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;

} */


.abudhabi {
  padding-top: 3rem;
}

.icon-header-popup-model{

  display: flex;
  justify-content: center;
  align-items: center;


}

.icon-header-popup-model img {

width: 250px;


}

.Purple-cards-img2{

  margin-top: 4rem;
  width: 100%;
}
@media (max-width: 480px) {
   .flex-register {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
  }
  .eagle-cont {
    padding-top: 0rem;
  }
  
  .form-registration-ADIHEX{
    margin-top: 2rem;
    width: 100%;
    border-radius: 9px ;
    /* background: red; */
    /* box-shadow: 1px 0px 9.3px 0px rgba(0, 0, 0, 0.25); */
    padding: 3rem ;
    padding-top: 1rem;
    box-shadow: 1px 0px 9.3px 0px #00000040;
  
  }
  
  .abudhabi img {
    width: 100%;
  } 
  .eagle-cont img {
    width: 100%;
  }
  
  .icon-header-popup-model img {

    width: 200px;
    
    
    }

    .Purple-cards-img2{

      margin-top: 0rem;
      width: 100%;
    }
  
  /* .eagle-cont2 {
    padding-top: 0rem;
  } */


/* .eagle-cont2 .eagle2{

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0rem;

} */
  /* .eagle-cont img {
    width: 100%;
  }

  .abudhabi img {
    width: 100%;
  } 
/* 
  .form-registration2 {
    width: 100%; 
    height: 100%;
    padding: 20px; 
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  } */
  /* .form-sign-in-1{
    margin-top: 1rem;
    border-radius: 9px ;
    background: rgba(255, 255, 255, 1);
    box-shadow: 1px 0px 9.3px 0px rgba(0, 0, 0, 0.25);
    padding: 2rem ;
    padding-top: 2rem;
    width: 100%;
    height: 100%;
  
  }
   */

/* .form-registration-ADIHEX {
  margin-top: 2rem;
  width: 100%;
  left: 100%;
  gap: 0px;
  border-radius: 9px ;
  opacity: 0px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 1px 0px 9.3px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem ;
  padding-top: 1rem;
} */

/* 
.eagle-cont4 .eagle3 {

  margin-top: 1rem;
  width: 100%;


} */
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
   .flex-register {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    padding-bottom: 2rem;
  } 

  .form-registration-ADIHEX{
    margin-top: 2rem;
    width: 100%;
    border-radius: 9px ;
    /* background: red; */
    /* box-shadow: 1px 0px 9.3px 0px rgba(0, 0, 0, 0.25); */
    padding: 3rem ;
    padding-top: 1rem;
    box-shadow: 1px 0px 9.3px 0px #00000040;
  
  }

  
   /* .form-registration2 {
    width: 100%; 
    height: 100%;
    padding: 3rem; 
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }  */

  /* .eagle-cont2 .eagle2{

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
  
  } */

/* .form-registration {
  margin-top: 2rem;
  width: 544px;
  left: 795px;
  gap: 0px;
  border-radius: 9px ;
  opacity: 0px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 1px 0px 9.3px 0px rgba(0, 0, 0, 0.25);
  padding: 3rem ;
  padding-top: 1rem;
} */


/* .form-sign-in-1{
  margin-top: 1rem;
  border-radius: 9px ;
  background: rgba(255, 255, 255, 1);
  box-shadow: 1px 0px 9.3px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem ;
  padding-top: 2rem;
  width: 100%;
  height: 100%;

} */

/* .eagle-cont4 .eagle3 {

  margin-top: 1rem;
  width: 100%;
} */
}
