.class-of-icons {
  display: flex;
  column-gap: 5%;
  padding-bottom: 1rem;
}

.list-unstyled li a {
  color: black !important;
  text-decoration: none;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
}

.list-unstyled{ 
  padding-top: 0.7rem;

}

.list-unstyled li {
  margin-bottom: 0.3rem !important;
  
}


.text-white-2 {
  color: #6423DB !important;
  text-decoration: none;
  font-size: 17px;
  font-family: Poppins;
  font-weight: 500;
}

.text-white-3{

  font-weight: 400 !important;
  font-size: 15px !important;
}
.logo-paragraph {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  width: 75%;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.icons-sec {
  padding-top: 4rem;
}
#footer {
  background-color: rgba(248, 248, 248, 1);
  margin-top: auto !important;
  height: auto;
}

.icons-sec img {
}

#centerrow {
}
.span-for-logo-title{
  color: #4925e1 ;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins;
  margin-left: 0.3rem;
  padding-top: 20px;
  text-decoration: none;
  
}

@media (max-width: 480px) {
  #centerrow {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .icons-sec {
    padding: 0rem;
    padding-bottom: 0.5rem;
  }

  .class-of-icons {
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .logo-container-paragraph{
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .icons-sec img {
    width: 100%;
  }
}
