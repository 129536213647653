.forgetform {
  box-shadow: 0px 1px 8.6px 2.73px rgba(114, 112, 112, 0.49);
  margin-top: 6rem;
  width: 820px !important;
  height: auto;
  border-radius: 6.13px 0px 0px 0px;
  margin-bottom: 5rem;
  padding: 5rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 12rem;
}

.forgetformTap{
  box-shadow: 0px 1px 8.6px 2.73px rgba(114, 112, 112, 0.49);
  margin-top: 6rem;
  width: 820px !important;
  height: auto;
  border-radius: 6.13px 0px 0px 0px;
  margin-bottom: 5rem;
  padding: 5rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 12rem;    
}

.form-title2 h6 {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
  color: #6423DB;
}



@media (max-width: 480px) {

  .forgetform {
    width: 90%;
    padding: 20px; /* Reduce padding for smaller screens */
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-top: 8rem;

  }

  .forgetformTap {
    width: 90%;
    padding: 20px; /* Reduce padding for smaller screens */
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-top: 8rem;

  }
  
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .forgetform {
    width: 100%;
    
    padding: 3rem; /* Reduce padding for smaller screens */
    margin-left: auto;
    margin-right: auto;
    margin-top: 14rem;

  }
  .forgetformTap {
    width: 100%;
    
    padding: 3rem; /* Reduce padding for smaller screens */
    margin-left: auto;
    margin-right: auto;
    margin-top: 14rem;

  }
}